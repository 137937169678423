<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "containers",
              "add-reception-desk-to-container",
              "add-reception-desks-to-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName(
                'containers',
                'add-reception-desk-to-container',
                'search'
              )
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="receptionDesks && receptionDesks.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="desk in receptionDesks"
        :key="desk.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedReceptionDesk(desk)"
        >
          <span class="label">
            <span class="highlight">{{ desk.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="
                selectedReceptionDesks &&
                  selectedReceptionDesks.filter(item => item.id === desk.id)
                    .length
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "ContainersAddReceptionDesk",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  created() {
    this.getReceptionDesksList();
  },
  computed: {
    ...mapState("containers", [
      "listOfReceptionDesks",
      "selectedReceptionDesks",
      "receptionDesksInContainer",
      "selectedContainer"
    ]),
    receptionDesks() {
      const query = this.searchQuery;
      if (this.listOfReceptionDesks && this.listOfReceptionDesks.length) {
        if (query && query.length > 1) {
          return this.listOfReceptionDesks.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.listOfReceptionDesks;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("containers", [
      "getReceptionDesksList",
      "setSelectedReceptionDesk"
    ])
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
